import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"

import HungerGraph from "@/components/graphs/HungerGraph"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"

import Motif from "@/components/general/Motif"
import Footer from "@/components/general/Footer"
import Share from "@/components/general/Share"

import openerText from "@/images/hunger/opener-text.png"
import openerTextSml from "@/images/hunger/opener-text-sml.png"

import dcm from "@/images/hunger/dcm.png"
import dcw from "@/images/hunger/dcw.png"
import shareImg from "@/images/og/og_hunger.jpg"

import SignupPopup from "@/components/general/SignupPopup"

const HungerPage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/hunger"
                title="Free Markets Destroy | Learn How Free Markets Destroy Hunger"
                description="Most people agree that markets are great at making stuff, but can markets actually solve humanity's toughest problems?"
                img={shareImg}
            />

            <Opener
                pageName="hunger"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            />

            {
                // ======================================= SECTION 1 =======================================
            }

            <ColorChangeBG
                from={{ r: 255, g: 255, b: 255 }}
                to={{ r: 183, g: 131, b: 185 }}
            >
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 4,
                                width: 9,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <div className="copy--lead">
                        <Dropcap img={dcm}>M</Dropcap>ost people agree that
                        markets are great at making stuff. Hiking boots, bicycle
                        helmets, electric cars and iPads—nearly all the products
                        that make our lives safer, easier, and more fun are the
                        result of market forces. And while it’s wonderful to
                        have access to such amazing tools, even an innovation as
                        revolutionary as the washing machine is still, on some
                        level, just more stuff. If you’re at all skeptical about
                        free markets, then it’s fair to ask if they can do more
                        than fill up our stores and homes with toys. Can markets
                        actually solve humanity's toughest problems?
                    </div>

                    <p className="copy">
                        It’s hard to imagine a tougher and more persistent human
                        problem than hunger. For thousands of years, hunger was
                        the norm for most of the people on the planet, and even
                        the richest kings and sultans were vulnerable to famine.
                    </p>
                    <p className="copy">
                        But starting in the 1700s, everything changed thanks in
                        part to one of the greatest innovations in human
                        history—specialization. Specialization is the division
                        of work into narrow, “specialized” niches. It’s the
                        reason why you can put food on your table by cutting
                        hair, or writing code for video games. And that’s the
                        product of free markets.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                marginTop: 1,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="hunger" id="motif_f" movement={0.1} />
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 6,
                                marginTop: 4,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="hunger" id="cover_b" movement={0.2} />
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 23,
                                marginLeft: 0.5,
                                marginBottom: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 2,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <Image
                        src="hunger_tractor"
                        width={{ width: "100%" }}
                    ></Image>
                </Sizing>

                <div style={{ float: "left", clear: "left" }}>
                    <SignupPopup></SignupPopup>
                </div>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 18,
                                marginLeft: 2,

                                marginTop: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 4,
                                minWidth: "350px",
                                marginTop: 2,
                                // marginBottom: 1,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Signup color={"#ec5843"}></Signup>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 8,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginRight: 4,
                                width: 8,
                                marginTop: 2,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <div className="copy">
                        <Dropcap img={dcw}>W</Dropcap>
                        ithout specialization, everyone needs to grow their own
                        food, sew their own clothes, and build their own homes.
                        Once we begin to specialize, workers and entrepreneurs
                        can focus on specific tasks, like growing wheat. Their
                        expertise allows them to produce a surplus, which they
                        trade with clothes makers and home builders. Pretty
                        quickly, everyone becomes better off. And because the
                        farmer needs the builder and vice versa, they are more
                        likely to treat each other with tolerance and respect.
                    </div>
                    <p className="copy">
                        And as farmers specialized—one growing corn, another
                        growing apples—they started to experiment with new tools
                        and techniques: crop rotation, selective breeding,
                        fertilization, and many more. Gradually, one innovation
                        at a time, farmers managed to make their labor more
                        efficient and more profitable.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                width: 14,
                                marginTop: 2,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 1,
                                    top: -1,
                                    right: -1,
                                },
                            },
                        ]}
                    >
                        <Motif page="hunger" id="motif_a" movement={-0.1} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 1,
                                    top: 3,
                                    left: -2,
                                },
                            },
                        ]}
                    >
                        <Motif page="hunger" id="motif_c" movement={0.2} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 1.5,
                                    bottom: -2,
                                    left: 1,
                                },
                            },
                        ]}
                    >
                        <Motif page="hunger" id="motif_e" movement={-0.1} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 3,
                                    bottom: -2,
                                    right: -2,
                                },
                            },
                        ]}
                    >
                        <Motif page="hunger" id="cover_h" movement={0.1} />
                    </Sizing>

                    <Image src="hunger_pizza" width={{ width: "100%" }}></Image>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative", zIndex: 4 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 4,
                                width: 8,

                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                    ]}
                >
                    <p className="copy">
                        From the time of the American Revolution to today, the
                        per capita supply of calories in the West has gone up
                        more than 70 percent. The picture is even more
                        impressive when you zoom in on the poorest sections of
                        society. Two-hundred years ago, the poorest 20% of
                        individuals in England and France lacked the energy for
                        sustained work. In other words, the poorest people—those
                        who desperately needed opportunities —couldn’t work
                        because they didn’t have enough to eat. That’s the very
                        definition of a vicious circle.
                    </p>

                    <Sizing
                        styles={{ position: "absolute", zIndex: 2 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 2,
                                    bottom: -2,
                                    right: -2,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="hunger"
                            id="motif_f"
                            movement={-0.1}
                            rotation={-0.1}
                        />
                    </Sizing>
                </Sizing>
            </ColorChangeBG>

            <HungerGraph></HungerGraph>

            <Sizing
                styles={{ position: "relative", zIndex: 4 }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            width: 24,
                            marginTop: 2,
                            marginBottom: 2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginTop: 2,
                            marginBottom: 2,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        },
                    },
                ]}
            >
                <Sizing
                    styles={{ position: "absolute", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 2,
                                top: -3,
                                left: 4,
                            },
                        },
                    ]}
                >
                    <Motif page="hunger" id="motif_d" movement={0.1} />
                </Sizing>
                <Sizing
                    styles={{ flexGrow: 0, flexShrink: 0 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                                marginBottom: 2,
                                float: "left",
                                paddingLeft: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: "auto",
                                marginLeft: 0,
                                marginBottom: 0,
                                float: "none",

                                paddingLeft: 3,
                                flexBasis: 7,
                            },
                        },
                    ]}
                >
                    <p className="copy">
                        Of course, hunger is still a challenge for millions of
                        people around the world, but the progress we have made
                        in just a few hundred years is nothing short of
                        astonishing. From 1800 to 2010 the price of wheat,
                        humanity’s most ubiquitous staple food, fell more than
                        88%. During the same period, the share of the world’s
                        population living in so-called “absolute poverty,”
                        surviving in the shadow of starvation, has fallen from
                        94% to just 9.6%.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ flexGrow: 0, flexShrink: 0 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 22,
                                marginLeft: 1,
                                marginBottom: 4,
                                float: "left",
                                paddingLeft: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: "auto",
                                marginLeft: 0,
                                marginBottom: 0,
                                float: "none",

                                paddingLeft: 2,
                                flexBasis: 10,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    {" "}
                    <Image
                        src={"hunger_isle"}
                        style={{ width: "100%" }}
                    ></Image>{" "}
                </Sizing>
            </Sizing>

            <Spacing space="0" />
            <Sizing
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 20,
                            maxWidth: "300px",
                            height: "50px",
                            marginTop: 2,
                            paddingBottom: 5,
                            position: "relative",
                            display: "block",
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            display: "none",
                        },
                    },
                ]}
            >
                <Share></Share>
            </Sizing>

            <Spacing space="0" />
            <FooterNav next={"rents"} previous={"disease"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default HungerPage
